@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 255 153 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.okvip-app {
  height: 100%;
}

input:focus {
  transform: none !important;
}

body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

body::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

body::-webkit-scrollbar-corner {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 8px;
}

.swiper-slide {
  text-align: center;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiperDate {
  --swiper-navigation-size: 20px;
  --swiper-theme-color: #ffffff;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  height: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  width: 20px !important;
  z-index: 11;
}

.swiper-button-prev {
  left: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.swiper-button-next {
  right: 0;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.okvip-input-number,
.okvip-input-number-input-wrap {
  padding: 0;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.okvip-input-number-input {
  padding: 5px 5px !important;
  font-weight: 600;
  text-align: center !important;
}

.okvip-statistic-content-value {
  font-size: 16px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0 !important;
}

.new88 .swiper-button-prev,
.new88 .swiper-button-next {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.new88 .swiper-slide-active {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.new88 .bg-score {
  background-image: linear-gradient(to bottom, #ff9800, #fe4451);
}

.shbet .swiper-button-prev,
.shbet .swiper-button-next {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}

.shbet .swiper-slide-active {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}

.shbet .bg-score {
  background-image: linear-gradient(to bottom, #6cb5cc, #2f7899);
}

.okvip-modal-body p img {
  max-width: 100%;
}

.border-item {
  --tw-border-opacity: 1;
  border-color: #6cb5cc;
}

.menu-cate{
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  color: #fff;
  text-decoration: none;
}
.thumb-icon{
    display: flex;
    max-width: 100%;
    display: inline-block;
    position: relative;
}
.text-menu{
    text-transform: uppercase;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.item-nav{
  display: flex;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  align-content: center;
  transition: .4s;
  height: 40px;
  padding: 0 12px;
  /* &:hover,&.active {
      // background: linear-gradient(to top, #fe8100, #fead00);
      background-image: linear-gradient(180deg, #ff9800, #fe4451);
      // transform: translateY(-4px);
  }
  &.jun88cmd {
      &:hover,&.active {
          border-bottom: 2px solid #FFF;
          background: #108ABD;
      }
  } */
}

.text-menu{
  text-transform: uppercase;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.thumb-icon{
    display: flex;
    max-width: 100%;
    display: inline-block;
    position: relative;
}
.menu-cate{
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
    color: #fff;
    text-decoration: none;
}

.modal-history .okvip-modal-title{
  text-align: center;
}
.modal-jun88k36 table td{
  border: 1px solid #f8f8ff;
}
.modal-jun88k36 .okvip-modal-content{
  padding: 15px;
}
.content-km{
  font-family: Times New Roman, Times, serif !important;
}
.okvip-modal{
  top: 50px;
  max-width: 1024px !important;
  width: 100% !important;
}